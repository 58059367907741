<template>
  <v-dialog
    v-model="modalData.dialog"
    persistent
    max-width="650px"
    scrollable
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="ml-4 mb-2"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("FORMS.new") }} {{ $t("FORMS.user") }}
      </v-btn>
    </template>

    <v-card v-if="formModel">
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system && formModel"
                v-model="formModel.status"
                :items="statuses.system.user"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="formModel.sites"
                :items="sites"
                :label="$t('FORMS.sites')"
                item-text="name"
                item-value="id"
                chips
                multiple
                :rules="siteRules"
                :id="dynamicID"
                deletable-chips="true"
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.sites)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="formModel.user_groups"
                :items="userGroups"
                :label="$t('FORMS.userGroups')"
                item-text="groupName"
                item-value="id"
                chips
                multiple
                :rules="userGroupRules"
                :id="dynamicID"
                deletable-chips="true"
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.user_groups)"
                    >{{ item.groupName }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-select
                v-model="formModel.roles"
                :items="roles"
                :label="$t('FORMS.roles')"
                item-text="name"
                item-value="id"
                chips
                multiple
                :rules="roleRules"
                :id="dynamicID"
                deletable-chips="true"
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.roles)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="formModel.christian_name1"
                :rules="nameRules"
                :label="$t('FORMS.firstName')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="formModel.christian_name2"
                :label="$t('FORMS.middleName')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="formModel.surname"
                :rules="nameRules"
                :label="$t('FORMS.lastName')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :append-icon="eyeIcon"
                v-model="formModel.password"
                :label="$t('FORMS.password')"
                :type="passIntputType"
                @click:append="toggleShowPass"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="formModel.locale"
                :items="languages"
                item-text="name"
                item-value="lang"
                :label="$t('FORMS.language')"
                :rules="languageRules"
                :id="dynamicID"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="formModel.email"
                :rules="emailRules"
                label="Email"
                :id="dynamicID"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox
                v-model="formModel.login"
                :label="$t('FORMS.login')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-checkbox
                v-model="formModel.newsletter"
                :label="$t('FORMS.newsletter')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="6" md="4"> </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//import Multiselect from "vue-multiselect";
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "UserForm",
  //components: { Multiselect },
  props: [
    "modalData",
    "userGroups",
    "roles",
    "statuses",
    "permissions",
    "sites",
  ],
  data() {
    return {
      formModel: null,
      formValid: false,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      languages: i18nService.languages,
      eyeIcon: "mdi-eye",
      passIntputType: "password",
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      emailRules: [
        (v) => !!v || this.$t("ERRORS.email_is_required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("ERRORS.email_is_valid"),
      ],
      languageRules: [(v) => !!v || this.$t("ERRORS.language_is_required")],
      siteRules: [
        (v) => (v && v.length > 0) || this.$t("ERRORS.site_is_required"),
      ],
      userGroupRules: [
        (v) => (v && v.length > 0) || this.$t("ERRORS.user_group_is_required"),
      ],
      roleRules: [
        (v) => (v && v.length > 0) || this.$t("ERRORS.role_is_required"),
      ],
    };
  },
  mounted: function () {},
  computed: {
    dynamicID() {
      // return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString());

      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    // ...mapState(["statuses"]),
    formTitle() {
      return this.modalData.editedIndex === -1
        ? this.$t("FORMS.new")
        : this.$t("FORMS.edit") + this.formModel.name;
    },
    // formModel() {
    //   return this.modalData.editedItem;
    // },
  },

  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        this.formModel = value.editedItem;
      },
    },
  },

  methods: {
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },
    toggleShowPass() {
      if (this.eyeIcon == "mdi-eye-off") {
        this.passIntputType = "password";
        this.eyeIcon = "mdi-eye";
      } else {
        this.passIntputType = "text";
        this.eyeIcon = "mdi-eye-off";
      }
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
      this.eyeIcon = "mdi-eye";
      this.passIntputType = "password";
    },
    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.user." + action);
    },

    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
  },
};
</script>
